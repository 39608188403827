<template>
  <div class="content-container">
    <!-- container element -->
    <div class="wrapper">
      <!-- Center center align -->
      <div class="field">
        <!-- White page align -->
        <ul class="flex-outer">
          <h1>{{ $t("help.helpP") }}</h1>
          <h5>{{ $t("help.description") }}</h5>
          <li class="">
            <div
              class="titleBox"
              :class="{
                activeTitleBox: currentSelected == 'search',
              }"
            >
              <div class="btfc-center">
                {{ $t("general.search") }}
                <input class="inputBoxSearch" type="text" v-model="search" />
              </div>
            </div>
            <transition name="slideMessage" mode="out-in">
              <div v-if="currentSelected == 'search'" class="elementOutputBox">
                <ul class="">
                  <li
                    v-for="v in results"
                    :key="v.result"
                    class="questionResult"
                    @click="scrollTo(v.key)"
                  >
                    {{ v.result }}
                  </li>
                </ul>
              </div>
            </transition>
          </li>
          <li class="" v-for="s in subjects" :key="s.subject">
            <a
              @click="changeCurrent(s.subject)"
              class="titleBox"
              :class="{
                activeTitleBox: currentSelected == s.subject,
              }"
            >
              <div class="subjectTitle">
                <div class="btfc-left">
                  <font-awesome-icon
                    class="arrowInfo"
                    icon="arrow-right"
                    size="1x"
                    :class="{
                      rotated: currentSelected == s.subject,
                    }"
                  ></font-awesome-icon>
                  <h2>
                    {{ s.subject }}
                  </h2>
                </div>
              </div>
            </a>
            <transition name="slideMessage" mode="out-in">
              <div v-if="currentSelected == s.subject" class="elementOutputBox">
                <div
                  v-for="ela in elements[s.elements]"
                  :key="ela.element"
                  :id="ela.element"
                >
                  <h3>{{ $t("help.T" + ela.element) }}</h3>
                  <h5>
                    <span v-html="$t('help.D' + ela.element)"></span>
                  </h5>
                </div>
              </div>
            </transition>
          </li>
        </ul>
        <!-- <div class="btfc-center">
					<button class="flex-button">
						<font-awesome-icon icon="trash" class="buttonIcon" />
						ADD
					</button>
					<button class="flex-button">
						LONGER EDIT TEXT
					</button>
					<button class="flex-button">
						<font-awesome-icon icon="trash" class="buttonIcon" />
						Delete
					</button>
				</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../components/i18n.js";
export default {
  name: "Help",
  data: function () {
    return {
      currentSelected: null,
      search: null,
      results: [],
      subjects: [
        { subject: "Players", elements: "elPlayers" },
        { subject: "Account", elements: "elAccount" },
        { subject: "Messages", elements: "elMessages" },
        { subject: "General", elements: "elGeneral" },
      ],
      elements: {
        elPlayers: [
          { element: "howAddPlayer" },
          { element: "howEditPlayer" },
          { element: "howDeletePlayer" },
          { element: "lostQRcode" },
          { element: "whereIsQRcode" },
          { element: "howRequestPlayer" },
        ],
        elAccount: [
          { element: "howCreate" },
          { element: "gotExistingAccount" },
          { element: "forgotPassword" },
          { element: "changeEmail" },
          { element: "changePassword" },
          { element: "deleteAccount" },
        ],
        elMessages: [
          { element: "whatIsMessages" },
          { element: "iWantToReply" },
          { element: "whereAreUnreadMessages" },
        ],
        elGeneral: [{ element: "contact" }, { element: "gotRequestSpecial" }],
      },
    };
  },
  methods: {
    changeCurrent(payload) {
      payload = payload == this.currentSelected ? false : payload;
      this.currentSelected = payload;
    },
    changedSearch(payload) {
      console.log(payload);
    },
    scrollTo(payload) {
      this.currentSelected = payload[0].replace("el", "");
      let id = payload[1];
      setTimeout(function () {
        console.log(document.getElementById(id));
        document.getElementById(id).scrollIntoView(true);
      }, 500);
      //console.log(this.$refs.id);
      //console.log(id);
      //var elmnt = document.getElementById(payload[1]);
      //console.log(elmnt);
      //elmnt.scrollIntoView();
    },
  },
  watch: {
    search(newValue) {
      this.currentSelected = "search";
      var value = newValue.toUpperCase();
      const resulted = [];
      const library = this.elements;
      this.results = [];

      Object.keys(library).forEach(function (key) {
        library[key].forEach(function (v) {
          if (
            i18n
              .t("help.T" + v.element)
              .toUpperCase()
              .includes(value) ||
            i18n
              .t("help.D" + v.element)
              .toUpperCase()
              .includes(value)
          ) {
            resulted.push({
              key: [key, v.element],
              result: i18n.t("help.T" + v.element),
            });
          }
        });
      });
      this.results = resulted;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.inputBoxSearch {
  width: 50%;
  margin: 8px;
  border-radius: 6px;
}
.questionResult {
  text-align: left;
  max-width: 100%;
  margin-top: 10px;
  margin-left: 10px;
}
.questionResult:hover {
  text-decoration: underline;
}
.titleBox {
  width: 100%;
  border-radius: 8px;
  padding-left: 2em;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.titleBox:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.activeTitleBox {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.subjectTitle {
  margin: 0px;
  padding: 2px;
  max-width: 85%;
}
.elementOutputBox {
  margin-top: 0px;
  width: 100%;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.05);
}
.elementOutputBox > ul {
  padding-left: 0px;
}
.elementOutputBox > div > h3 {
  text-align: left;
  margin-left: 15px;
  margin-bottom: 10px;
}
.elementOutputBox > div > h5 {
  max-width: 100%;
  margin-top: 10px;
}
.slideMessage-enter-active,
.slideMessage-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
}

.slideMessage-enter-to,
.slideMessage-leave {
  max-height: 500px;
  overflow: hidden;
}

.slideMessage-enter,
.slideMessage-leave-to {
  overflow: hidden;
  max-height: 0px;
}
.arrowInfo {
  margin-right: 15px;
  display: inline-block;
  transition: all 0.5s;
}
.rotated {
  transform: rotate(90deg);
}
</style>
