<template>
  <div class="content-container">
    <!-- container element -->
    <div class="wrapper">
      <!-- Center center align -->
      <div class="field">
        <!-- White page align -->
        <div v-if="!loading">
          <ul class="flex-outer">
            <h1>{{ $t("players.addPlayerQR") }}</h1>

            <div v-if="error">
              <h5 style="color:red">{{ error }}</h5>
            </div>
            <div v-else>
              <h3>Player {{ playerInfo.id }} - {{ playerInfo.name }}</h3>
              <h5>{{ playerInfo.address }}</h5>
              <div v-if="loggedIn">
                <form
                  ref="newPlayerSubmitForm"
                  @submit.prevent="addPlayerConfirm"
                >
                  <ul class="flex-outer">
                    <li>
                      <h5>{{ $t("players.renamePlayer") }}</h5>
                      <input
                        type="text"
                        class="inputBox"
                        style="width:40%;"
                        id="Name"
                        :value="playerInfo.name"
                      />
                    </li>
                    <button class="flex-button" type="submit">
                      <div v-if="!loading">
                        {{ $t("general.confirmButton") }}
                      </div>
                      <div v-else>
                        <div class="loader"></div>
                      </div>
                    </button>
                  </ul>
                </form>
              </div>
              <div v-else>
                <h3>
                  {{ $t("players.welcomeQR") }}
                </h3>
                <li class="btfc-center">
                  <button class="flex-button" @click="changePage('login')">
                    {{ $t("menu.login") }}
                  </button>
                  <button class="flex-button" @click="changePage('signup')">
                    {{ $t("menu.signup") }}
                  </button>
                </li>
              </div>
            </div>
          </ul>
        </div>
        <div v-else>
          <div class="bigLoader">
            <h2 class=""></h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "addPlayerQR",
  data: function() {
    return {};
  },
  mounted() {
    this.initPlayerID();
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    error() {
      return this.$store.state.error;
    },
    loggedIn() {
      return this.$store.getters.user;
    },
    playerInfo() {
      return this.$store.state.newPlayer;
    },
  },
  
  methods: {
    initPlayerID() {
      if (this.$route.params.playerID) {
        this.$store.dispatch("initHoldAddPlayer", this.$route.params.playerID);
      } else {
        this.$router.push("/home");
      }
    },
    changePage(payload) {
      this.clearError();
      // add holdPlayerAdd function
      this.$router.push("/" + payload);
    },
    changeError(payload) {
      this.$store.commit("changeError", payload);
    },
    clearError() {
      this.$store.commit("clearError");
    },
    addPlayerConfirm() {
      if (this.$refs.newPlayerSubmitForm.Name.value == "") {
        this.changeError("The name can't be empty");
      } else {
        this.$store.dispatch("addNewPlayer", {
          name: this.$refs.newPlayerSubmitForm.Name.value,
        });
        this.$store.commit("newPlayer", []);
        this.$router.push("/");
      }
    },
  },
  beforeCreate() {
    this.$store.commit("clearError");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
