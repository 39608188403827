<template>
  <div class="content-container">
    <div class="wrapper">
      <div class="field">
        <h2 v-if="!passwordForgotError">
          {{ requestPasswordForgotten.title }}
        </h2>
        <h5 v-if="!passwordForgotError">
          {{ requestPasswordForgotten.subTitle }}
        </h5>
        <h3 v-if="passwordForgotError" style="color:red;">
          {{ passwordForgotError }}
        </h3>
        <form v-if="!sendedOrNot" @submit.prevent="onPasswordForgot">
          <ul class="flex-outer">
            <li>
              <label for="email">Email</label>
              <input
                v-model="email"
                type="email"
                id="email"
                :placeholder="$t('auth.enterMailHere')"
                required
              />
            </li>
            <li>
              <div class="btfc-center">
                <button class="flex-button" type="submit">
                  <div v-if="!loading">{{ $t("general.sentButton") }}</div>
                  <div v-else>
                    <div class="loader"></div>
                  </div>
                </button>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
    };
  },
  computed: {
    passwordForgotError() {
      return this.$store.state.error;
    },
    loggedIn() {
      return this.$store.state.user.id;
    },
    loading: function() {
      return this.$store.state.loading;
    },
    requestPasswordForgotten() {
      if (this.$store.state.passwordForgot == false) {
        return {
          title: this.$i18n.t("auth.forgotPaswTitle"),
          subTitle: this.$i18n.t("auth.forgotPaswDescription"),
        };
      } else {
        return {
          title: this.$i18n.t("auth.forgotPaswSentTitle"),
          subTitle: this.$i18n.t("auth.forgotPaswSentDescr"),
        };
      }
    },
    sendedOrNot() {
      return this.$store.state.passwordForgot;
    },
  },
  methods: {
    onPasswordForgot() {
      this.$store.commit("clearError");
      this.$store.dispatch("onPasswordForgot", { email: this.email });
    },
  },
  name: "Signup",
};
</script>

<style scoped>
li {
  text-align: left;
}
</style>
