<template>
  <body id="app">
    <pwaIosPrompt></pwaIosPrompt>
    <meta
      name="viewport"
      content="width=device-width, height=device-height,  initial-scale=1.0, user-scalable=no, user-scalable=0"
    />
    <nav class="main-nav">
      <img class="logo" src="./assets/logo_dark.png" />
      <transition name="fade" mode="out-in">
        <button
          v-if="unreadMessages > 0 && this.$route.name !== 'messages'"
          @click="goToMessages"
          class="unreadMessageTop"
        >
          <h4>
            <font-awesome-icon icon="envelope" class="topMenuIcon" />
            {{ $tc("menu.unreadMessages", unreadMessages) }}
          </h4>
        </button>
      </transition>
      <Burger></Burger>
    </nav>
    <Menu />

    <div
      :class="{
        'slideIn-content': isPanelOpen,
        'slideOut-content': !isPanelOpen,
      }"
    >
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <v-dialog :width="300" />
  </body>
</template>

<script>
import Menu from "./components/menu.vue";
import Burger from "./components/menu/burger.vue";
import pwaIosPrompt from "./components/pwaIosPrompt.vue";

export default {
  name: "GPM Rating",
  components: {
    Menu,
    Burger,
    pwaIosPrompt,
  },
  computed: {
    isPanelOpen() {
      return this.$store.getters.navOpenOrNot;
    },
    notification() {
      return this.$store.state.notification;
    },
    unreadMessages() {
      return this.$store.state.user.unreadMessages;
    },
  },
  methods: {
    goToMessages() {
      //console.log(this.$route)
      this.$router.push("/messages");
    },
  },
  watch: {
    isPanelOpen() {
      if (this.$store.getters.navOpenOrNot == true) {
        document.body.className = "openSidebar";
      } else {
        document.body.className = "closedSidebar";
      }
    },
    notification() {
      if (this.notification.icon == "done") {
        var element = document.getElementsByClassName("circle-loader");
        element[0].classList.toggle("load-complete");
        var element2 = document.getElementsByClassName("checkmark");
        element2[0].style.display = "block";
      }
      if (this.notification.icon == "loadError") {
        console.log("toggle class");
        var elementError = document.getElementsByClassName("circle-loader");
        elementError[0].classList.toggle("load-complete-error");
      }
      this.$modal.hide("dialog");
      if (this.notification.show == true) {
        let feedback = [];
        if (
          this.notification.icon == "loading" ||
          this.notification.icon == "done" ||
          this.notification.icon == "loadError"
        ) {
          feedback = {
            buttons: [],
            title: this.notification.title,
            text:
              '<div class="circle-loader"><div class="checkmark draw"></div></div>',
          };
          if (
            this.notification.icon == "done" ||
            this.notification.icon == "loadError"
          ) {
            feedback["buttons"] = [
              {
                title: "Close",
                default: true,
                handler: () => {
                  this.$store.commit("setNotification", { show: false });
                  this.$modal.hide("dialog");
                },
              },
            ];
          }
          if (this.notification.icon == "loadError") {
            feedback["text"] =
              '<div class="errormark">X</div><br>' + this.notification.text;
          }
        } else {
          feedback = {
            buttons: [
              {
                title: "Close",
                default: true,
                handler: () => {
                  this.$store.commit("setNotification", { show: false });
                  this.$modal.hide("dialog");
                },
              },
            ],
            title: "<h2>" + this.notification.title + "</h2>",
            text: this.notification.text,
          };
        }
        this.$modal.show("dialog", {
          title: feedback.title,
          text: feedback.text,
          buttons: feedback.buttons,
        });
      }
    },
  },
  beforeCreate: function () {
    document.body.className = "closedSidebar";
    document.title = "GPM Rating";
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,600&display=swap");

html {
  height: 100%;

  overflow: hidden;
}

body {
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Raleway", Arial, sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(50%, #a58ba3),
    color-stop(50%, #a58ba3),
    color-stop(90%, #bfada3)
  );
  /*background-color: #a58ba3; */
  overflow: hidden;
}

body.closedSidebar {
  background-color: #a58ba3;
  transition: background-color 300ms ease-in-out;
}
body.openSidebar {
  background-color: #332d2c;
  transition: background-color 300ms ease-in-out;
}

.menuHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-flow: space-between;
  align-items: center;
}

.menuLogo {
  float: right;
  margin: 1em;
  height: 40px;
  /*filter: brightness(500%) invert(100%); */
}

.fade-leave-active,
.fade-enter-active {
  transition: transform 0.3s ease;
}
.fade-enter {
  transform: translate(0%, -100%);
}
.fade-leave-to {
  transform: translate(0%, 100%);
}

.fadeLR-leave-active,
.fadeLR-enter-active {
  transition: transform 0.2s ease, opacity 0.2s ease;
}
.fadeLR-enter {
  opacity: 0;
  transform: translate(100%, 0%);
}
.fadeLR-leave-to {
  opacity: 0;
  transform: translate(-100%, 0%);
}

.slideIn-content,
.slideOut-content {
  height: 100%;
}
@media (max-width: 1025px) {
  .slideIn-content {
    opacity: 0.5;
    transform: translateX(10%);
    transition: transform 1s ease, opacity 1s ease;
  }

  .slideOut-content {
    opacity: 1;
    transform: translateX(0%);
    transition: transform 0.5s ease, opacity 0.5s ease;
  }
}

.logo {
  align-self: center;
  color: #fff;
  height: 40px;
}

@media (max-width: 1025px) {
  .menuLogo {
    display: none;
  }
  .main-nav {
    display: flex;
    max-height: 40px;
    justify-content: space-between;
    padding: 0.5rem 0.8rem;
  }
}
@media (min-width: 1025px) {
  .main-nav {
    display: none;
  }
  .content-container {
    padding-top: 40px;
  }
}

/* Old from here on */

.content-container {
  height: 90%;
  display: grid;
  overflow: scroll;
}

.wrapper {
  width: 95%;
  max-width: 33em;
  margin: auto;
}

.field {
  margin: auto;
  padding: 3%;
  background-color: rgba(255, 253, 252, 0.9);
  border-radius: 10px;
  max-height: 95%;
  overflow: inherit;
}

.wrapperd * {
  box-sizing: border-box;
}

.wrapperScanner div {
  margin: 0px;
  margin-bottom: 7px;
  width: 100%;
}

.flex-outer,
.flex-inner {
  list-style-type: none;
  padding: 0;
}

.flex-outer {
  max-width: 800px;
  margin: 0 auto;
}

.flex-outer li,
.flex-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flex-inner {
  padding: 0 8px;
  justify-content: space-between;
}

.flex-outer > li:not(:last-child) {
  margin-bottom: 20px;
}

.flex-outer li label,
.flex-outer li p {
  text-align: left;
  padding: 8px;
  font-weight: 300;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}

@media screen and (min-width: 479px) {
  .flex-outer > li > label,
  .flex-outer li p {
    flex: 1 0 120px;
    max-width: 45%;
  }
  .flex-outer > li > label + *,
  .flex-inner {
    flex: 1 0 45%;
  }
  .flex-outer li h5 {
    max-width: 45%;
    text-align: left;
    margin-left: 1em;
  }
}
@media screen and (max-width: 479px) {
  .flex-outer > li > label,
  .flex-outer li p {
    flex: 1 0 120px;
    max-width: 100%;
  }
  .flex-outer > li > label + *,
  .flex-inner {
    flex: 1 0 80%;
  }
  .flex-outer > li > input,
  .flex-inner {
    flex: 1 0 80%;
  }
  .flex-outer li h5 {
    max-width: 100%;
    text-align: left;
    margin-left: 1em;
  }
}

.flex-outer li p {
  margin: 0;
}

.flex-outer li a {
  font-size: 1em;
  font-weight: 300;
  padding-left: 8px;
  color: #2c3e50;
  text-decoration: none;
}

.flex-outer li input:not([type="checkbox"]),
.flex-outer li textarea {
  padding: 15px;
  border: none;
}

.flex-button {
  padding: 12px 20px;
  background: #333;
  color: #f2f2f2;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  border-radius: 6px;
  border: 0px;
  margin: 8px;
}

.flex-button:disabled {
  background: #dddddd;
}

.flex-button-container {
  margin: auto;
  border: none;
}

.flex-inner li {
  width: 100px;
}

.buttonIcon {
  font-size: 13px;
  margin-left: -6px;
  padding-right: 3px;
  color: white;
}

.iconMenu {
  position: absolute;
  margin-left: -10px;
  padding-top: 1px;
  color: white;
}

.camera {
  border-radius: 8px;
}

/* CONTAINER BUILD FOR BUTTONS */
.btfc-space-between,
.btfc-space-around,
.btfc-left,
.btfc-right,
.btfc-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
.btfc-space-between {
  justify-content: space-between;
}
.btfc-space-around {
  justify-content: center;
}
.btfc-left {
  justify-content: flex-start;
}
.btfc-center {
  justify-content: center;
}

.btfcColumn-center,
.btfcColumn-full-width {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
.btfcColumn-center {
  justify-content: center;
  align-items: center;
}
.btfcColumn-full-width {
  justify-content: center;
}
@media (max-width: 1025px) {
  .btfc-right {
    justify-content: space-between;
  }
}
@media (min-width: 1025px) {
  .btfc-right {
    justify-content: flex-end;
  }
}

/* END OF BUTTON CONTAINER */

.inputBox {
  margin: 8px;
  border-radius: 6px;
}

.btn-loader {
  margin: auto;
  padding: 12px 20px;
  border: none;
  background: #333;
  color: #f2f2f2;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  border-radius: 2px;
}
.loader {
  border: 2px solid #333; /* Light grey */
  border-top: 2px solid #f3f3f3; /* Blue */
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 2s ease infinite;
  margin: auto;
}

.bigLoader h2 {
  border: 4px solid #333; /* Light grey */
  border-top: 4px solid #f3f3f3; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s ease infinite;
  margin: auto;
}

.bigLoader {
  margin: 3px;
  padding: 20%;
}

.v--modal-overlay {
  background: rgba(0, 0, 0, 0.6) !important;
}
.v--modal {
  border-radius: 8px !important;
  text-align: center !important;
}
.vue-dialog-button {
  background-color: #333333 !important;
  color: #f2f2f2 !important;
  margin: 7px !important;
  border-radius: 6px;
}
.vue-dialog .vue-dialog-buttons {
  border-top: 0px !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circle-loader {
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 7em;
  height: 7em;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border 500ms ease-out;
}
.load-complete-error {
  -webkit-animation: none;
  animation: none;
  border-color: #d43333;
  transition: border 500ms ease-out;
}
.errormark {
  color: #d43333;
  font-size: 4em;
  font-weight: bold;
  text-align: center;
  width: 100%;
  height: 1em;
  border-radius: 5px;
}

.checkmark {
  display: none;
}
.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark:after {
  opacity: 1;
  height: 3.5em;
  width: 1.75em;
  transform-origin: left top;
  border-right: 3px solid #5cb85c;
  border-top: 3px solid #5cb85c;
  content: "";
  left: 1.75em;
  top: 3.5em;
  position: absolute;
}

.unreadMessageTop {
  display: flex;
  border-radius: 20px;
  padding: 0px;
  background-color: rgba(217, 30, 24, 0.9);
  border: 0px;
}
.unreadMessageTop > h4 {
  display: flex;
  margin: 10px;
  font-size: 15px;
}
.topMenuIcon {
  margin-top: 1px;
  padding-right: 6px;
}
.ifList {
  display: list-item !important;
}

.selectBox {
  width: 80%;
  margin: auto;
}
.selectBox .vs__search::placeholder,
.selectBox .vs__dropdown-toggle,
.selectBox .vs__dropdown-menu {
  border-radius: 6px;
  width: 100%;
  background: #dddddd;
  border: none;
  color: #2d3e50;
  font-family: "Raleway", Arial, sans-serif;
}
.selectBox .vs__actions {
  padding-right: 10px !important;
}
.selectBox:not(.daminSelectBox).vs__selected {
  height: 100%;
  margin: auto;
}
.daminSelectBox {
  height: auto !important;
}

.vs__dropdown-option {
  padding: 1em !important;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 1.75em;
    opacity: 1;
  }
  40% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
  100% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
}
</style>
