<template>
  <div class="content-container">
    <div class="wrapper">
      <div class="field">
        <h2>{{ $t("auth.signUpTitle") }}</h2>
        <h3 v-if="signUpError" style="color:red;">{{ signUpError }}</h3>
        <form @submit.prevent="onSignup">
          <ul class="flex-outer">
            <li>
              <label for="email">Email</label>
              <input
                v-model="email"
                type="email"
                id="email"
                :placeholder="$t('auth.enterMailHere')"
                required
              />
            </li>
            <li>
              <label for="password">{{ $t("auth.password") }}</label>
              <input
                v-model="password"
                type="password"
                id="password"
                :placeholder="$t('auth.enterPaswHere')"
                required
              />
            </li>
            <li>
              <label for="confirmPassword">{{
                $t("auth.passwordAgain")
              }}</label>
              <input
                v-model="confirmPassword"
                type="Password"
                id="confirmPassword"
                :placeholder="$t('auth.enterPaswHere')"
                required
              />
            </li>
            <li>
              <div class="btfc-center">
                <button
                  class="flex-button"
                  @click="goBack()"
                  v-if="holdAddPlayer"
                >
                  Go back
                </button>
                <button class="flex-button" type="submit">
                  <div v-if="!loading">{{ $t("general.sentButton") }}</div>
                  <div v-else>
                    <div class="loader"></div>
                  </div>
                </button>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
    };
  },
  computed: {
    signUpError() {
      return this.$store.state.error;
    },
    registered() {
      return this.$store.state.user.registered;
    },
    loading: function() {
      return this.$store.state.loading;
    },
    holdAddPlayer: function() {
      return this.$store.state.holdAddPlayer;
    },
  },
  watch: {
    registered(value) {
      if (value == 1) {
        this.$router.push("/signUpP2");
      }
    },
  },
  methods: {
    onSignup() {
      if (this.password !== this.confirmPassword) {
        this.$store.commit("changeError", this.$i18n.t("auth.errorPassw"));
      } else {
        this.$store.commit("clearError");
        this.$store.dispatch("signUserUp", {
          email: this.email,
          password: this.password,
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  name: "Signup",
  beforeCreate() {
    this.$store.commit("clearError");
  },
};
</script>

<style scoped>
li {
  text-align: left;
}
</style>
