<template>
  <div class="content-container">
    <div v-if="!loading" class="wrapper">
      <div class="field">
        <!-- White page align -->
        <h3>{{ $t("messages.welcome", { firstName: user.firstname }) }}</h3>
        <h5 v-if="totalMessages.length > 0">
          {{ $t("messages.description") }}
        </h5>
        <h5 v-else>{{ $t("messages.noMails") }}</h5>
        <ul class="flex-outer boxMessages">
          <li v-for="rates in totalMessages" :key="rates.rate">
            <div class="liTitleBox">
              <a
                class="titleBox"
                @click="changeCurrent(rates.rate)"
                :class="{ activeTitleBox: rates.rate == currentSelected }"
              >
                <div class="infoRate">
                  <div v-if="rates.rateInfo.type !== undefined">
                    <h3>
                      <span v-if="rates.rateInfo.type == 'up'">
                        <font-awesome-icon
                          icon="thumbs-up"
                          size="1x"
                        ></font-awesome-icon>
                      </span>
                      <span v-else>
                        <font-awesome-icon
                          icon="thumbs-down"
                          size="1x"
                        ></font-awesome-icon>
                      </span>
                      {{ rates.rateInfo.type }} rate of
                      {{ rates.rateInfo.date | moment("from", "now") }}
                    </h3>
                  </div>
                  <div v-else-if="rates.rate == 'R00000'">
                    <h3>
                      {{
                        $t("messages.welcomeContentTitle", {
                          firstname: user.firstname,
                        })
                      }}
                    </h3>
                  </div>
                  <div v-else>
                    <h3>{{ $t("messages.rateNotFound") }}</h3>
                  </div>
                </div>
                <div class="iconRate">
                  <div v-if="rates.unread !== 0">
                    <font-awesome-layers>
                      <font-awesome-icon
                        icon="bell"
                        size="2x"
                      ></font-awesome-icon>

                      <font-awesome-icon
                        icon="circle"
                        size="2x"
                        transform="left-5 up-5"
                        style="color: rgba(255, 0, 0, 0.8)"
                      ></font-awesome-icon>
                      <font-awesome-layers-text
                        class="fa-inverse"
                        transform="up-11 left-2"
                        :value="rates.unread"
                        style="font-weight: 900"
                      />
                    </font-awesome-layers>
                  </div>
                </div>
              </a>
              <transition name="slideMessage" mode="out-in">
                <div
                  v-if="currentSelected == rates.rate"
                  class="messageOutputBox"
                >
                  <ul>
                    <li class="topInfoRate">
                      <h6>
                        {{ $t("messages.rateFrom") }}
                        {{
                          rates.rateInfo.date
                            | moment("dddd, MMMM Do YYYY, h:mm:ss a")
                        }}
                      </h6>
                    </li>
                    <messageContainer
                      v-for="msg in rates.messages"
                      :from="msg.from"
                      :date="msg.created"
                      :rec="msg.rcvd"
                      :msg="newLined(msg.msg)"
                      :key="msg.created"
                    ></messageContainer>
                  </ul>
                  <div
                    v-if="rates.rateInfo.type !== undefined"
                    class="messageSentBox"
                  >
                    <input
                      v-model="sentContext"
                      v-on:keyup.13="sentMessageButton()"
                      class="inputSentBox"
                      :placeholder="$t('messages.writeMessage')"
                      type="text"
                      name=""
                    />
                    <button class="sentButton" @click="sentMessageButton()">
                      <div v-if="!sentMessageloading">
                        {{ $t("general.sentButton") }}
                      </div>
                      <div v-else>
                        <div class="loader"></div>
                      </div>
                    </button>
                  </div>
                </div>
              </transition>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div key="loading" v-else class="wrapper">
      <div class="bigLoader">
        <h2 class=""></h2>
      </div>
    </div>
  </div>
</template>

<script>
import messageContainer from "./tools/messageContainer.vue";
export default {
  name: "settings",
  components: {
    messageContainer,
  },
  data: function () {
    return {
      sentContext: null,
    };
  },
  computed: {
    user() {
      return {
        firstname: this.$store.state.user.firstname,
        lastname: this.$store.state.user.lastname,
        companyname: this.$store.state.user.companyname,
        companylocation: this.$store.state.user.companylocation,
      };
    },
    loading() {
      return this.$store.state.loading;
    },
    totalMessages() {
      return this.$store.state.user.totalMessages;
    },
    currentSelected() {
      return this.$store.state.currentRateOfMsg;
    },
    sentMessageloading() {
      return this.$store.state.sentMessageLoading;
    },
  },
  methods: {
    intToDate(int) {
      var myDate = new Date(int);
      return myDate.toLocaleString();
    },
    changeCurrent(payload) {
      payload = payload == this.currentSelected ? false : payload;
      this.$store.dispatch("dispSwitchCurrentRateMsg", payload);
    },
    sentMessageButton() {
      var context = this.sentContext;
      this.sentContext = null;
      this.$store.dispatch("addMessage", context);
    },
    newLined(payload) {
      /* eslint-disable */
      var regex = /<br\s*[\/]?>/gi;
      /* eslint-enable */
      return payload.replace(regex, "\n");
    },
  },
  mounted() {
    if (this.$store.state.user.id !== null) {
      this.$store.dispatch("getMessages", this.$store.state.user.id);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.boxMessages {
  width: 100%;
  flex-direction: column;
}
.liTitleBox {
  flex: 0 0 100%;
}
.titleBox {
  border-radius: 8px;
  padding-left: 2em;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.activeTitleBox {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.topInfoRate {
  margin: 0px 0px 5px 0px;
}
.topInfoRate > h6 {
  margin: 0px auto;
  font-weight: 500;
}
.infoRate {
  margin: 0px;
  padding: 2px;
  max-width: 85%;
}
.iconRate {
  width: 2em;
  padding: 2px;
  margin: 20px;
  max-width: 10%;
}
.messageOutputBox {
  margin-top: 0px;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.05);
}
.messageOutputBox > ul {
  padding-left: 0px;
}
.messageSentBox {
  width: 100%;
  display: table;
  height: 3em;
}
.sentButton,
.inputSentBox {
  display: table-cell;
}
.inputSentBox {
  width: calc(100% - 13em);
  padding: 12px !important;
  border-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.sentButton {
  padding: 12px 20px;
  background: #333;
  color: #f2f2f2;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  border-radius: 6px;
  border: 0px;
  margin: 8px;
  margin-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.sentButton:disabled {
  background: #dddddd;
}

.slideMessage-enter-active,
.slideMessage-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
}

.slideMessage-enter-to,
.slideMessage-leave {
  max-height: 250px;
  overflow: hidden;
}

.slideMessage-enter,
.slideMessage-leave-to {
  overflow: hidden;
  max-height: 0px;
}
</style>
