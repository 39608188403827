<template>
  <div class="content-container">
    <div v-if="!loading" class="wrapper">
      <div class="field">
        <h2>{{ $t("general.step") }} 2/3</h2>
        <h3>{{ $t("auth.alreadyAccountP2Title") }}</h3>
        <h5>{{ $t("auth.alreadyAccountP2Description") }}</h5>
        <h3 v-if="signUpError" style="color: red">{{ signUpError }}</h3>
        <form @submit.prevent="alreadyAccountP2action">
          <ul class="flex-outer">
            <li>
              <label for="first-name">{{ $t("general.firstName") }}</label>
              <input
                v-model="firstname"
                type="text"
                id="firstname"
                :placeholder="$t('auth.enterFirstNameHere')"
              />
            </li>
            <li>
              <label for="last-name">{{ $t("general.lastName") }}</label>
              <input
                v-model="lastname"
                type="text"
                id="lastname"
                :placeholder="$t('auth.enterLastNameHere')"
              />
            </li>
            <li>
              <label for="last-name">{{ $t("general.companyName") }}</label>
              <input
                v-model="companyname"
                type="text"
                id="companyname"
                :placeholder="$t('auth.enterCompanyNameHere')"
              />
            </li>
            <li>
              <label for="last-name" v-html="$t('general.companyLoca')"></label>
              <input
                v-model="companylocation"
                type="text"
                id="companylocation"
                :placeholder="$t('auth.enterCompanyLocaHere')"
              />
            </li>
            <li>
              <div class="btfc-center">
                <button class="flex-button" type="submit">
                  <div v-if="!loading">{{ $t("general.sentButton") }}</div>
                  <div v-else>
                    <div class="loader"></div>
                  </div>
                </button>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
    <div v-else class="wrapper">
      <div class="field">
        <div class="bigLoader">
          <h2 class=""></h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "alreadyAccountP2",
  data() {
    return {
      companylocation: "",
      doneBigLoading: false,
    };
  },
  computed: {
    firstname() {
      return this.$store.state.user.firstname;
    },
    lastname() {
      return this.$store.state.user.lastname;
    },
    companyname() {
      return this.$store.state.user.companyname;
    },
    signUpError() {
      return this.$store.state.error;
    },
    loading: function () {
      return this.$store.state.loading;
    },
    registered() {
      return this.$store.state.user.registered;
    },
  },
  methods: {
    alreadyAccountP2action() {
      if (
        this.firstname !== null &&
        this.firstname !== undefined &&
        this.lastname !== null &&
        this.lastname !== undefined &&
        this.companyname !== null &&
        this.companyname !== undefined &&
        this.companylocation !== null &&
        this.companylocation !== undefined
      ) {
        this.$store.commit("clearError");
        const firstnameC = this.capitalized(this.firstname);
        const lastnameC = this.capitalized(this.lastname);
        const companynameC = this.capitalized(this.companyname);
        const companylocationC = this.capitalized(this.companylocation);
        this.$store.dispatch("changeUserInfo", {
          firstname: firstnameC,
          lastname: lastnameC,
          companyname: companynameC,
          companylocation: companylocationC,
        });
      } else {
        this.$store.commit("changeError", this.$i18n.t("auth.errorEmptyF"));
      }
    },
    capitalized: function (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  beforeCreate() {
    this.$store.commit("clearError");
  },
  watch: {
    registered(value) {
      if (value == 3) {
        //this.$router.push("/alreadyAccountP3");
        // Skip part 3
        this.$router.push("/home");
        this.$store.commit("setNotification", {
          show: true,
          icon: "success",
          title: "Success!",
          text: this.$i18n.t("auth.finishedRegistration"),
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  text-align: left;
}
</style>
