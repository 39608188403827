<template>
  <div class="content-container">
    <div key="content" class="wrapper">
      <h3 v-if="error" style="color: red">{{ error }}</h3>
      <transition name="fadeLR" mode="out-in">
        <div key="log" class="field" v-if="aPage == 0">
          <h2>Admin account</h2>
          <form @submit.prevent="onDaminSignIn">
            <ul class="flex-outer">
              <li>
                <label for="email">Email</label>
                <input
                  v-model="email"
                  type="email"
                  id="email"
                  :placeholder="$t('auth.enterMailHere')"
                  required
                />
              </li>
              <li>
                <label for="password">{{ $t("auth.password") }}</label>
                <input
                  v-model="password"
                  type="password"
                  id="password"
                  :placeholder="$t('auth.enterPaswHere')"
                  required
                />
              </li>
              <li>
                <div class="btfc-center">
                  <button class="flex-button" type="submit">
                    <div v-if="!loading">Log in</div>
                    <div v-else>
                      <div class="loader"></div>
                    </div>
                  </button>
                </div>
              </li>
            </ul>
          </form>
        </div>
        <div key="suc" class="field" v-if="aPage == 1">
          <h2>Add player by mail</h2>
          <form @submit.prevent>
            <ul class="flex-outer">
              <li>
                <label for="M">To:</label>
                <input
                  v-model="toM"
                  type="email"
                  id="M"
                  :placeholder="$t('auth.enterMailHere')"
                />
              </li>
              <li>
                <v-select
                  id="playerSelect"
                  multiple
                  class="selectBox daminSelectBox"
                  :placeholder="$t('home.clickSelectPlayer')"
                  label="location"
                  :options="$store.state.allPlayers"
                  v-model="playerAddList"
                />
              </li>
              <li>
                <div class="btfc-center">
                  <button class="flex-button" @click="sentM()" type="submit">
                    <div v-if="!loading">Sent</div>
                    <div v-else>
                      <div class="loader"></div>
                    </div>
                  </button>
                </div>
              </li>
            </ul>
          </form>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  name: "damin",
  data: function () {
    return {
      email: null,
      password: null,
      toM: null,
      playerAddList: null,
    };
  },
  computed: {
    aPage() {
      return this.$store.state.aPage;
    },
    ifDamin() {
      return this.$store.state.user.damin;
    },
    loading: function () {
      return this.$store.state.loading;
    },
    error() {
      return this.$store.state.error;
    },
  },
  watch: {},
  methods: {
    onDaminSignIn() {
      this.$store.dispatch("onDaminSignIn", {
        email: this.email,
        password: this.password,
      });
    },
    /* eslint-disable */
    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    /* eslint-enable */
    sentM() {
      if (
        this.playerAddList !== null &&
        Object.keys(this.playerAddList).length > 0
      ) {
        this.$store.commit("clearError");
        if (this.validateEmail(this.toM)) {
          var playerLister = "";
          const playerObject = [];
          for (var pl in Object.entries(this.playerAddList)) {
            playerLister +=
              "<li style='text-align:left;'>" +
              this.playerAddList[pl].playerId +
              " - " +
              this.playerAddList[pl].location +
              "</li>";
            playerObject.push({
              location: this.playerAddList[pl].location,
              playerId: this.playerAddList[pl].playerId,
              key: this.playerAddList[pl].crypKey,
            });
          }
          this.$modal.show("dialog", {
            text:
              "Are you sure you want to send an email with the following players? <br> <ul>" +
              playerLister +
              "</ul>",
            buttons: [
              {
                title: "No",
                default: true,
              },
              {
                title: "Yes",
                handler: () => {
                  this.$modal.hide("dialog");
                  const getResponse = () => {
                    try {
                      return axios.post(
                        process.env.VUE_APP_APIURL + "/mail/sentMailToClient",
                        {
                          email: this.toM,
                          players: playerObject,
                        }
                      );
                    } catch (error) {
                      console.error(error);
                    }
                  };

                  const sentResponse = async () => {
                    getResponse()
                      .then((response) => {
                        if (response.data == "ok") {
                          this.$store.commit("setNotification", {
                            show: true,
                            icon: "success",
                            title: "Succesfully sent mail to client",
                          });
                        } else {
                          this.$store.commit("setNotification", {
                            show: true,
                            icon: "error",
                            title: "Not succeeded sending mail to client",
                          });
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  };

                  sentResponse();
                },
              },
            ],
          });
        } else {
          this.$store.commit("changeError", "Please enter a valid email");
        }
      } else {
        this.$store.commit(
          "changeError",
          "You must select at least one player"
        );
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
