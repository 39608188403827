import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "./translations.js";
Vue.use(VueI18n);

// Create VueI18n instance with options
// const initLang = (() => {
// 	let lang = window.localStorage.getItem("LANG") || window.navigator.language;
// 	return lang.slice(0, 2).toLowerCase() || "nl";
// })();

const i18n = new VueI18n({
	locale: "nl",
	messages, // set locale messages
});

export default i18n;
