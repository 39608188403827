<template>
  <Sidebar>
    <div class="bm-item-list">
      <div class="menuHeader">
        <h4>
          {{ $t("general.welcome") }} <br />
          {{ userTitle }}
        </h4>
        <img class="menuLogo" src="../assets/logo.png" />
      </div>
      <div class="changeLanguage">
        <span
          ><a
            v-on:click="switchLocale()"
            class="hoverLocale"
            v-html="displayLocale"
          ></a>
        </span>
      </div>
      <hr :class="{ hrIn: isPanelOpen }" />

      <router-link
        v-for="item in menuLoggedIn"
        :key="item.to"
        v-on:click.native="closeSidebarPanel"
        :to="{ name: item.to }"
      >
        <div v-if="item.icon == 'envelope' && unreadMessages > 0">
          <font-awesome-layers class="iconMenu">
            <font-awesome-icon :icon="item.icon" class="iconMenu" />
            <font-awesome-icon
              icon="circle"
              size="1x"
              transform="right-4 up-4"
              style="color: rgba(255, 0, 0, 0.8)"
              >2</font-awesome-icon
            >
            <font-awesome-layers-text
              class="fa-inverse"
              transform="up-8 right-5"
              :value="unreadMessages"
              style="font-size: 16px; font-weight: 500"
            />
          </font-awesome-layers>
        </div>
        <div v-else>
          <font-awesome-icon :icon="item.icon" class="iconMenu" />
        </div>
        <span>{{ $t("menu." + item.span) }}</span>
      </router-link>

      <hr v-if="userIsAuthenticated" :class="{ hrIn: isPanelOpen }" />
      <router-link
        v-for="item in menuNotLogged"
        :key="item.to"
        v-on:click.native="closeSidebarPanel"
        :to="{ name: item.to }"
      >
        <font-awesome-icon :icon="item.icon" class="iconMenu" /><span>{{
          $t("menu." + item.span)
        }}</span>
      </router-link>

      <a href="#" v-if="userIsAuthenticated" @click="onLogOut">
        <font-awesome-icon icon="sign-out-alt" class="iconMenu" /><span>{{
          $t("menu.logout")
        }}</span>
      </a>
    </div>
  </Sidebar>
</template>

<script>
import Sidebar from "./menu/sidebar.vue";

export default {
  name: "Menu",
  components: {
    Sidebar,
  },
  methods: {
    closeSidebarPanel() {
      this.$store.commit("setIsNavOpen", false);
    },
    onLogOut() {
      this.$store.dispatch("logout");
      this.closeSidebarPanel();
      this.$router.push({ name: "start" });
    },
    switchLocale() {
      if (this.$i18n.locale === "nl") {
        this.$i18n.locale = "en";
        window.localStorage.setItem("LANG", "en");
      } else {
        this.$i18n.locale = "nl";
        window.localStorage.setItem("LANG", "nl");
      }
    },
  },
  computed: {
    displayLocale() {
      let other =
        '<span class="hoverUnderline"> Switch to EN </span> | <span class="hoverNotUnderline">NL</span>';
      if (this.$i18n.locale === "en") {
        other =
          '<span class="hoverUnderline"> Wissel naar NL </span> | <span class="hoverNotUnderline">EN</span>';
      }
      return other;
    },
    userTitle() {
      return this.$store.state.user.firstname == ""
        ? "Please sign in"
        : this.$store.state.user.firstname;
    },
    isPanelOpen() {
      return this.$store.getters.navOpenOrNot;
    },
    menuLoggedIn() {
      let menuItemsLoggedIn = [];
      if (this.userIsAuthenticated) {
        menuItemsLoggedIn = [
          { to: "home", span: "home", icon: "home" },
          { to: "players", span: "players", icon: "hdd" },
          { to: "messages", span: "messages", icon: "envelope" },
          { to: "help", span: "help", icon: "info-circle" },
        ];
      }
      return menuItemsLoggedIn;
    },
    menuNotLogged() {
      let menuItemsLoggedOut = [
        { to: "login", span: "login", icon: "sign-in-alt" },
        { to: "signup", span: "signup", icon: "user-plus" },
      ];
      if (this.userIsAuthenticated) {
        menuItemsLoggedOut = [
          { to: "settings", span: "settings", icon: "user-cog" },
        ];
      }
      return menuItemsLoggedOut;
    },
    userIsAuthenticated() {
      return (
        this.$store.state.user.id !== undefined &&
        this.$store.state.user.id !== null &&
        this.$store.state.user.id !== ""
      );
    },
    unreadMessages() {
      return this.$store.state.user.unreadMessages;
    },
  },
};
</script>

<style>
.hoverUnderline:hover {
  text-decoration: underline;
}
.hoverNotUnderline {
  text-decoration: underline;
}
.hoverNotUnderline:hover {
  text-decoration: none;
}

.changeLanguage {
  padding: 0px !important;
  margin: 0em 0em 0em -5em;
  width: 100%;
  font-size: 12px;
  display: flex row;
  justify-content: flex-end;
}

hr {
  height: 1px;
  border: 0;
  border-top: 2px solid #b8b7ad;
  margin: 1em 1em;
  padding: 0px !important;
  width: 0%;
}
.hrIn {
  -webkit-animation: lineMove 1s 1 forwards;
  animation: lineMove 1s 1 forwards;
}
h4 {
  margin-left: 10px;
  color: white;
  text-align: left;
}
/* is just overwriting helpers. You can even delete underneath CSS */
.bm-item-list {
  color: #b8b7ad;
  margin-left: 0%;
  font-size: 22px;
}
.bm-item-list > * {
  display: flex;
  text-decoration: none;
  padding: 0.5em;
}
.bm-item-list > * > span {
  margin-left: 30px;
  font-weight: 700;
  color: white;
}
@-webkit-keyframes lineMove {
  0% {
    width: 0%;
  }
  100% {
    width: 70%;
  }
}
@keyframes lineMove {
  0% {
    width: 0%;
  }
  100% {
    width: 70%;
  }
}
</style>
