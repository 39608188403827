<template>
  <div class="content-container">
    <div v-if="!loading && doneBigLoading" class="wrapper">
      <div class="field">
        <h3>{{ $t("auth.alreadyAccountTitle") }}</h3>
        <h5>{{ $t("auth.alreadyAccountDescription") }}</h5>
        <h3 v-if="signUpError" style="color: red">{{ signUpError }}</h3>
        <form @submit.prevent="onSignupFromOld">
          <ul class="flex-outer">
            <li>
              <label for="email">Email</label>
              <input
                v-model="email"
                type="email"
                id="email"
                :placeholder="$t('auth.enterMailHere')"
                required
              />
            </li>
            <li>
              <label for="password">{{ $t("auth.newPassword") }}</label>
              <input
                v-model="password"
                type="password"
                id="password"
                :placeholder="$t('auth.enterNewPaswHere')"
                required
              />
            </li>
            <li>
              <label for="confirmPassword">{{
                $t("auth.newPasswordAgain")
              }}</label>
              <input
                v-model="confirmPassword"
                type="Password"
                id="confirmPassword"
                :placeholder="$t('auth.enterNewPaswHere')"
                required
              />
            </li>
            <li>
              <div class="btfc-center">
                <button class="flex-button" type="submit">
                  <div v-if="!loading">{{ $t("general.next") }}</div>
                  <div v-else>
                    <div class="loader"></div>
                  </div>
                </button>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
    <div v-else class="wrapper">
      <div class="field">
        <div class="bigLoader">
          <h2 class=""></h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

function getCookieValue(a) {
  var b = document.cookie.match("(^|[^;]+)\\s*" + a + "\\s*=\\s*([^;]+)");
  return b ? unescape(b.pop()) : "";
}

export default {
  name: "alreadyAccount",
  data() {
    return {
      email: getCookieValue("sRemail"),
      password: "",
      confirmPassword: "",
      firstname: "",
      lastname: "",
      companyname: "",
      companylocation: "",
      regisplayers: null,
      doneBigLoading: false,
    };
  },
  computed: {
    registered() {
      return this.$store.state.user.registered;
    },
    signUpError() {
      return this.$store.state.error;
    },
    loading: function () {
      return this.$store.state.loading;
    },
  },
  methods: {
    validateUser() {
      this.$store.commit("setLoading", true);
      axios
        .get(process.env.VUE_APP_APIURL + "/userVallidate", {
          params: {
            email: getCookieValue("sRemail"),
            pwd: getCookieValue("sRpwd"),
          },
        })
        .then((response) => {
          this.companyname = response.data.company;
          this.firstname = response.data.firstName;
          this.lastname = response.data.lastName;
          this.regisplayers = response.data.players;
          this.$store.commit("setLoading", false);
          this.doneBigLoading = true;
        })
        .catch((error) => {
          try {
            this.$store.commit("changeError", error.response.data);
          } catch (err) {
            this.$store.commit(
              "changeError",
              "There is problem with connecting to our databases, pleasy try again later"
            );
          }
          this.$store.commit("setLoading", false);
          this.doneBigLoading = true;
        });
    },
    onSignupFromOld() {
      if (this.password !== this.confirmPassword) {
        this.$store.commit("changeError", this.$i18n.t("auth.errorPassw"));
      } else {
        this.$store.commit("clearError");
        this.$store.dispatch("signUserUpFromOld", {
          firstname: this.firstname,
          lastname: this.lastname,
          company: this.companyname,
          regplayers: this.regisplayers,
          email: this.email,
          password: this.password,
        });
      }
    },
  },
  beforeCreate() {
    this.$store.commit("clearError");
  },
  created() {
    this.validateUser();
  },
  watch: {
    registered(value) {
      if (value == 1) {
        this.$router.push("/alreadyAccountP2");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  text-align: left;
}
</style>
