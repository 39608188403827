<template>
  <div class="content-container">
    <div class="wrapper">
      <div class="field">
        <h2>{{ $t("auth.loginTitle") }}</h2>
        <h3 v-if="singInError" style="color:red;">{{ singInError }}</h3>
        <form @submit.prevent="onSignIn">
          <ul class="flex-outer">
            <li>
              <label for="email">Email</label>
              <input
                v-model="email"
                type="email"
                id="email"
                :placeholder="$t('auth.enterMailHere')"
                required
              />
            </li>
            <li>
              <label for="password">{{ $t("auth.password") }}</label>
              <input
                v-model="password"
                type="password"
                id="password"
                :placeholder="$t('auth.enterPaswHere')"
                required
              />
            </li>
            <li class="passwordForgotten">
              <router-link to="PasswordForgotten">
                <span> {{ $t("auth.forgotPaswButton") }} </span>
              </router-link>
            </li>
            <li>
              <div class="btfc-center">
                <button
                  class="flex-button"
                  @click="goBack()"
                  v-if="holdAddPlayer"
                >
                  Go back
                </button>
                <button class="flex-button" type="submit">
                  <div v-if="!loading">Log in</div>
                  <div v-else>
                    <div class="loader"></div>
                  </div>
                </button>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      passwordError: null,
    };
  },
  computed: {
    singInError() {
      return this.$store.state.error;
    },
    loggedIn() {
      return this.$store.state.user.id;
    },
    loading: function() {
      return this.$store.state.loading;
    },
    holdAddPlayer: function() {
      return this.$store.state.holdAddPlayer;
    },
  },
  watch: {
    loggedIn(value) {
      if (value !== undefined && value !== null && value !== "") {
        if (
          this.holdAddPlayer !== undefined &&
          this.holdAddPlayer !== null &&
          this.holdAddPlayer !== ""
        ) {
          this.$router.push("/addPlayerQR/" + this.holdAddPlayer);
        } else {
          this.$router.push("/");
        }
      }
    },
  },
  methods: {
    onSignIn() {
      this.$store.commit("clearError");
      this.$store.dispatch("onSignIn", {
        email: this.email,
        password: this.password,
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  beforeCreate() {
    this.$store.commit("clearError");
  },
  name: "Signup",
};
</script>

<style scoped>
li {
  text-align: left;
}
</style>
