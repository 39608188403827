<template>
  <div class="content-container">
    <div class="wrapper">
      <div class="field">
        <h3>{{ $t("auth.signupP2Title") }}</h3>
        <h3 v-if="signUpError" style="color:red;">{{ signUpError }}</h3>
        <form @submit.prevent="onSignupP2">
          <ul class="flex-outer">
            <li>
              <label for="first-name">{{ $t("general.firstName") }}</label>
              <input
                v-model="firstname"
                type="text"
                id="firstname"
                :placeholder="$t('auth.enterFirstNameHere')"
              />
            </li>
            <li>
              <label for="last-name">{{ $t("general.lastName") }}</label>
              <input
                v-model="lastname"
                type="text"
                id="lastname"
                :placeholder="$t('auth.enterLastNameHere')"
              />
            </li>
            <li>
              <label for="last-name">{{ $t("general.companyName") }}</label>
              <input
                v-model="companyname"
                type="text"
                id="companyname"
                :placeholder="$t('auth.enterCompanyNameHere')"
              />
            </li>
            <li>
              <label for="last-name">{{ $t("general.companyLoca") }}</label>
              <input
                v-model="companylocation"
                type="text"
                id="companylocation"
                :placeholder="$t('auth.enterCompanyLocaHere')"
              />
            </li>
            <li>
              <div class="btfc-center">
                <button class="flex-button" type="submit">
                  <div v-if="!loading">{{ $t("general.sentButton") }}</div>
                  <div v-else>
                    <div class="loader"></div>
                  </div>
                </button>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstname: null,
      lastname: null,
      companyname: null,
      companylocation: null,
    };
  },
  computed: {
    signUpError() {
      return this.$store.state.error;
    },
    firstnameStore() {
      return this.$store.state.user.firstname;
    },
    loading: function() {
      return this.$store.state.loading;
    },
    holdAddPlayer: function() {
      return this.$store.state.holdAddPlayer;
    },
  },
  watch: {
    firstnameStore(name) {
      if (name !== undefined && name !== null) {
        if (
          this.holdAddPlayer !== undefined &&
          this.holdAddPlayer !== null &&
          this.holdAddPlayer !== ""
        ) {
          this.$router.push("/addPlayerQR/" + this.holdAddPlayer);
        } else {
          this.$router.push("/");
        }
      }
    },
  },
  methods: {
    checkValidated: function() {
      if (this.$store.state.user.registered !== 1) {
        this.$router.push("/signUp");
      }
    },
    onSignupP2() {
      if (
        this.firstname !== null &&
        this.firstname !== undefined &&
        this.lastname !== null &&
        this.lastname !== undefined &&
        this.companyname !== null &&
        this.companyname !== undefined &&
        this.companylocation !== null &&
        this.companylocation !== undefined
      ) {
        this.$store.commit("clearError");
        const firstnameC = this.capitalized(this.firstname);
        const lastnameC = this.capitalized(this.lastname);
        const companynameC = this.capitalized(this.companyname);
        const companylocationC = this.capitalized(this.companylocation);
        this.$store.dispatch("signUserUpP2", {
          firstname: firstnameC,
          lastname: lastnameC,
          companyname: companynameC,
          companylocation: companylocationC,
        });
        this.$store.commit("setNotification", {
            show: true,
            icon: "success",
            title: "Success!",
            text: this.$i18n.t("auth.finishedRegistration"),
          });
      } else {
        this.$store.commit("changeError", this.$i18n.t("auth.errorEmptyF"));
      }
    },
    capitalized: function(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  beforeMount() {
    this.checkValidated();
  },
  beforeCreate() {
    this.$store.commit("clearError");
  },
  name: "SignupP2",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  text-align: left;
}
</style>
