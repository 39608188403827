<template>
  <div class="content-container">
    <div v-if="!loading" class="wrapper">
      <div class="field">
        <h2> {{ $t("general.step") }} 3/3 </h2>
        <h3>{{ $t("auth.alreadyAccountP3Title") }}</h3>
        <h5>{{ $t("auth.alreadyAccountP3Description") }}</h5>
        <h3 v-if="signUpError" style="color:red;">{{ signUpError }}</h3>
        <form ref="editPlayersForm" @submit.prevent="onEditPlayers">
              <ul class="flex-outer">
                <li
                  class="playerContainer"
                  v-for="player in registeredPlayers"
                  v-bind:key="player.id"
                >
                  <label v-bind:for="player.crypKey"
                    >Player {{ player.id }}
                    <font-awesome-icon icon="arrow-right" class="" />
                  </label>
                  <input
                    class="inputBox"
                    ref="inputName"
                    type="text"
                    :id="player.crypKey"
                    :value="player.name"
                  />
                </li>
                <li>
                  <div class="btfc-center">
                    <button
                      class="flex-button"
                      type="submit"> {{ $t("general.saveChangesButton") }}
                    </button>
                  </div>
                </li>
              </ul>
            </form>
      </div>
    </div>
    <div v-else class="wrapper">
      <div class="field">
        <div class="bigLoader">
          <h2 class=""></h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "alreadyAccountP3",
  data() {
    return {
      doneBigLoading: false,
    };
  },
  computed: {
    signUpError() {
      return this.$store.state.error;
    },
    loading: function() {
      return this.$store.state.loading;
    },
    registeredPlayers() {
      return this.$store.state.user.registeredPlayers;
    },
  },
  methods: {
    changeError(payload) {
      this.$store.commit("changeError", payload);
    },
    onEditPlayers() {
      let result = Object.values(this.$refs.inputName).map((a) => [
        a.value,
        a.id,
      ]); 
      let values = Object.values(this.$refs.inputName).map((a) => a.value);
      if (values.includes(undefined) || values.includes("")) {
        this.changeError(this.$i18n.t("general.cantBeEmpty"));
      } else {
        if (values == undefined && values == null) {
          this.changeError(this.$i18n.t("general.cantBeEmpty"));
        }
        this.$store.dispatch("editPlayerList", { input: result });
        this.$router.push("/home");
        this.$store.commit("setNotification", {
          show: true,
          icon: "success",
          title: "Success!",
          text: this.$i18n.t("auth.finishedRegistration"),
        });
      }
    },
  },
  beforeCreate() {
    this.$store.commit("clearError");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  text-align: left;
}
.playerContainer {
  background: rgba(0, 0, 0, 0.1);
  padding: 7px 7px;
  border: 0;
  border-radius: 10px;
  margin-top: 10px !important;
}
</style>
