import { render, staticRenderFns } from "./messages.vue?vue&type=template&id=7cbf4278&scoped=true&"
import script from "./messages.vue?vue&type=script&lang=js&"
export * from "./messages.vue?vue&type=script&lang=js&"
import style0 from "./messages.vue?vue&type=style&index=0&id=7cbf4278&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cbf4278",
  null
  
)

export default component.exports