<template>
  <div class="content-container">
    <div v-if="!loading" class="wrapper">
      <transition name="fadeLR" mode="out-in">
        <div key="ratePage" v-if="!rateOrChangeMood">
          <div v-if="userIsAuthenticated" class="field">
            <ul class="flex-outer">
              <h1>
                {{ $t("home.welcome", { firstName: firstname }) }}
              </h1>
              <li class="selectBoxContainer" v-if="countedPlayers > 1">
                <v-select
                  :searchable="countedPlayers > 4 ? true : false"
                  @input="setActivePlayer"
                  class="selectBox"
                  id="playerSelect"
                  :placeholder="$t('home.clickSelectPlayer')"
                  :value="$store.state.selectedPlayer"
                  label="name"
                  :options="$store.state.user.registeredPlayers"
                />
              </li>
              <li v-if="countedPlayers == 0">
                <h3 style="margin:auto;">{{ $t("home.noPlayers") }}</h3>
                <div class="btfc-center">
                  <button @click="moveTo('/players')" class="flex-button">
                    {{ $t("general.addPlayerButton") }}
                  </button>
                </div>
              </li>
              <div v-if="selectedPlayer">
                <div v-if="selectedIfPlaying">
                  <h3>
                    {{
                      $t("home.subtext", { playerName: selectedPlayer.name })
                    }}
                  </h3>
                  <div class="thumbsContainer">
                    <font-awesome-icon
                      icon="thumbs-up"
                      size="6x"
                      style="color:rgba(22, 129, 0, 0.9)"
                      :class="getClass('up')"
                      @click="changeStateRate('up')"
                    />
                    <font-awesome-icon
                      icon="thumbs-down"
                      size="6x"
                      style="color:rgba(217, 30, 24, 0.9)"
                      :class="getClass('down')"
                      @click="changeStateRate('down')"
                    />
                  </div>
                  <br />
                  <div class="btfcColumn-center">
                    <button
                      class="flex-button"
                      @click="rateButton(selectedPlayer.id)"
                      :disabled="!stateRate"
                    >
                      <font-awesome-icon icon="share" class="buttonIcon" />
                      {{ $t("home.rateButton") }}
                    </button>
                    <div v-if="selectedIfSwitchi">
                      <span style="letter-spacing: .09em;font-size: 11px;">
                        {{ $t("general.or") }}
                      </span>
                      <button
                        class="flex-button"
                        @click="moveToSwitch(selectedPlayer.id)"
                      >
                        <font-awesome-icon
                          icon="sliders-h"
                          class="buttonIcon"
                        />
                        {{ $t("home.changeVibeButton") }}
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <h3>{{ $t("home.standbyPlayer") }}</h3>
                  <font-awesome-layers>
                    <font-awesome-icon icon="satellite-dish" size="4x" />
                    <font-awesome-icon
                      icon="times"
                      size="5x"
                      style="color:rgba(217, 30, 24, 0.4);"
                      transform="right-2"
                    />
                  </font-awesome-layers>
                </div>
              </div>
            </ul>
          </div>
          <div v-else class="field">
            <h2>{{ $t("home.pleaseRegister") }}</h2>
          </div>
        </div>
        <div v-else key="changeVibePage" class="field">
          <ul class="flex-outer">
            <h3>
              {{
                $t("home.changeVibeText", { playerName: selectedPlayer.name })
              }}
            </h3>
            <div class="btfc-space-around">
              <div v-for="(style, index) in selectedSwitchOpt" :key="index">
                <!-- icon, name, stamp -->
                <switchOptionBox
                  @click.native="
                    changeStyle([selectedPlayer.id, style.name, style.stampID])
                  "
                  :name="style.name"
                  :icon="style.icon"
                  :stamp="style.stamp"
                  :active="style.active"
                ></switchOptionBox>
              </div>
            </div>
            <button class="flex-button" @click="moveToRate()">
              {{ $t("general.goBack") }}
            </button>
          </ul>
        </div>
      </transition>
    </div>
    <div v-else class="wrapper">
      <div class="field">
        <div class="bigLoader">
          <h2 class=""></h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import switchOptionBox from "./tools/switchOptionBox.vue";

export default {
  name: "Home",
  data: function() {
    return {
      stateRate: null,
    };
  },
  components: {
    switchOptionBox,
  },
  computed: {
    firstname() {
      return this.$store.state.user.firstname;
    },
    lastname() {
      return this.$store.state.user.lastname;
    },
    companyname() {
      return this.$store.state.user.companyname;
    },
    companylocation() {
      return this.$store.state.user.companylocation;
    },
    loading() {
      return this.$store.state.loading;
    },
    registeredPlayers() {
      return this.$store.state.user.registeredPlayers;
    },
    userIsAuthenticated() {
      return (
        this.$store.state.user.id !== undefined &&
        this.$store.state.user.id !== null &&
        this.$store.state.user.id !== ""
      );
    },
    countedPlayers() {
      return this.$store.getters.amountRegisteredPlayers;
    },
    selectedPlayer() {
      return this.$store.state.selectedPlayer;
    },
    selectedIfPlaying() {
      return this.$store.state.selectedIfPlaying;
    },
    rateOrChangeMood() {
      return this.$store.state.rateOrChangeMood;
    },
    selectedIfSwitchi() {
      return this.$store.state.selectedIfSwitchi;
    },
    selectedSwitchOpt() {
      return this.$store.state.selectedSwitchOpt;
    },
    switchLoading() {
      return this.$store.state.switchLoading;
    },
  },
  watch: {
    registeredPlayers(newCount, oldCount) {
      if (
        newCount !== oldCount &&
        newCount !== undefined &&
        Object.keys(newCount).length == 1
      ) {
        this.$store.dispatch(
          "switchSelectedPlayer",
          newCount[Object.keys(newCount)[0]]
        );
      } else if (newCount !== oldCount && newCount !== undefined) {
        this.$store.dispatch("switchSelectedPlayer", null);
      }
    },
  },
  methods: {
    rateButton(payload) {
      this.$store.dispatch("ratePlayer", { rate: this.stateRate, id: payload });
      this.stateRate = null;
    },
    setActivePlayer(val) {
      this.$store.dispatch("switchSelectedPlayer", val);
    },
    moveTo(where) {
      this.$router.push(where);
    },
    changeStateRate(type) {
      this.stateRate = type;
    },
    getClass(type) {
      return {
        selected: this.stateRate == type,
        notSelected: this.stateRate != type,
      };
    },
    moveToSwitch(payload) {
      this.$store.dispatch("moveToMoodSwitch", payload);
      // do something
    },
    moveToRate(payload) {
      this.$store.dispatch("moveToRatePage", payload);
    },
    changeStyle(payload) {
      this.$store.dispatch("changeStyle", payload);
    },
  },
  mounted() {
    if (this.countedPlayers == 1) {
      let val = this.$store.state.user.registeredPlayers[0];
      this.$store.dispatch("switchSelectedPlayer", val);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.thumbsContainer {
  padding: 10px;
  display: flex;
  justify-content: center;
}
.thumbsContainer svg {
  width: 140px;
  display: flex;
  justify-content: flex-start;
}

.selected {
  -webkit-animation: selected 0.4s ease infinite alternate-reverse both;
  animation: selected 0.4s ease infinite alternate-reverse both;
}
.notSelected {
  color: black;
}
.fa-layers {
  height: 5em !important;
  width: 5em !important;
}
@-webkit-keyframes selected {
  0% {
    filter: brightness(0.8);
    -webkit-filter: brightness(0.8);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    filter: brightness(1);
    -webkit-filter: brightness(1);
  }
}
@keyframes selected {
  0% {
    filter: brightness(0.8);
    -webkit-filter: brightness(0.8);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    filter: brightness(1);
    -webkit-filter: brightness(1);
  }
}
</style>
