<template>
    <div class="sidebar">
        <div
            class="sidebar-backdrop"
            @click="closeSidebarPanel"
            v-if="isPanelOpen"
        ></div>
        <transition name="slide">
            <div v-if="isPanelOpen" class="sidebar-panel">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    computed: {
        isPanelOpen() {
            return this.$store.getters.navOpenOrNot;
        },
    },
    methods: {
        closeSidebarPanel: function() {
            this.$store.commit("setIsNavOpen", false);
        },
    },
    mounted() {
        window.onresize = () => {
            if (window.innerWidth > 1025) {
                this.$store.commit("setIsNavOpen", true);
            }
        };
    },
};
</script>
<style>
.sidebar-backdrop {
    background-color: rgba(0, 0, 0, 0);
    width: 0;
    height: 0;
    position: fixed;
    top: 0;
    left: 0;
}
@media (min-width: 1025px) {
}
@media (max-width: 1025px) {
    .sidebar-backdrop {
        background-color: rgba(0, 0, 0, 0.4);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 998;
    }
    .slide-enter-active,
    .slide-leave-active {
        transition: transform 0.5s ease;
    }
    .slide-enter,
    .slide-leave-to {
        transform: translateX(-100%);
        transition: all 150ms ease-in 0s;
    }
}

.sidebar-panel {
    overflow-y: auto;
    background-color: #332d2c;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 999;
    padding: 0rem 20px 2rem 20px;
    width: 45%;
    max-width: 250px;
}
</style>
