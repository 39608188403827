import { store } from "@/stores/store";

function getCookies() {
  var pairs = document.cookie.split(";");
  var cookies = {};
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
  }
  return cookies;
}

export default (to, from, next) => {
  var cookies = getCookies();
  if (
    cookies.sRpwd !== undefined &&
    cookies.sRpwd !== null &&
    cookies.sRpwd !== "" &&
    cookies.sRemail !== undefined &&
    cookies.sRemail !== null &&
    cookies.sRemail !== "" &&
    !store.getters.user
  ) {
    next("/alreadyAccount");
  } else {
    next();
  }
};
